import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterOutlet,MatTabsModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})

export class HeaderComponent implements OnInit {

  navLinks: any[];
  
  constructor() {
    
    this.navLinks = [
      {
        label: 'work together',
        link: 'work-together',
        index: 0
      }, {
        label: 'pricing',
        link: 'pricing',
        index: 1
      }, {
        label: 'about me',
        link: 'about-me',
        index: 2
      }
    ];

  }

  getLogo() {
    return "https://storage.googleapis.com/"+ environment.mediaBucket + "/2023/10/f68d28b5-logo-black.png";
  }

  getSiteUrl() {
    return environment.siteUrl;
  }

  ngOnInit() {
  }

}
