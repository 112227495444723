<div class="page">
    <div class="section">
           
                <div class="page-title">
                    <p>
                        <strong>Biography</strong>
                    </p> 
                </div>
            
        <div class="row">
            <div class="column">
                <img decoding="async" fetchpriority="high" width="768" height="1024" src="{{ getAboutMeUrl() }}" alt=""  style="width:244px;height:325px" sizes="(max-width: 768px) 100vw, 768px">
            </div>
            
                <div class="column content"  style="flex-basis:40%">
                    <div style="width: 30%;">
                        <hr class="separator" style="background-color:#000000;color:#000000"/>
                    </div>
                            <p>
                                Father of three, automation and permaculture enthusiast. I travelled to many places (49 countries from my last count) both for business and pleasure. My strength is understanding complex systems and innovating with out-of-the-box solutions.

With a background in software development coding in languages like Java, C#, Python and Golang, I’ve always been drawn to the efficiency and precision that automation brings. This interest led me to specialise in automating delivery platforms, accelerating the value stream, and leveraging feedback loops for continuous improvement.
                            </p>
                </div>
                <div class="column content"  style="flex-basis:40%">
                    
                        <p>
                            As a Solution Architect, I’ve honed my training and public speaking skills, delivering presentations to audiences ranging from a few individuals to over a hundred people. I’ve also given conference talks and led marketing strategies targeting the developer community. In addition, I’ve developed tools to streamline the work of my teammates, such as environment provisioning for their demos or training.

I hold an AWS Certified Solution Architect – Professional certification, further solidifying my expertise in the field. I’m always eager to learn and adapt, staying abreast of the latest trends and technologies in the industry.
                        </p>
                </div>
        </div>
    </div>
    <div class="section" style="width: 100%;height: auto;padding: 0;">
        <object data="https://storage.googleapis.com/media-gajea-website-us/2023/10/9cd16580-resume-jonathan-roquelaure.pdf" type="application/pdf" width="100%" height="500px">
            <a href="{{ getResumePDFUrl() }}" >Download Resume PDF</a>
        </object>
    </div>
