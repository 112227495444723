import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkTogetherComponent } from './work-together/work-together.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { PricingComponent } from './pricing/pricing.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'work-together',
        pathMatch: 'full'
      },
    {
        path: 'work-together',
          component: WorkTogetherComponent 
    },
    {
        path: 'about-me',
        component: AboutMeComponent 
    },
    {
        path: 'pricing',
        component: PricingComponent 
    }            
    ];
