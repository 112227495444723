import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {

  getLogo() {
    return "https://storage.googleapis.com/"+ environment.mediaBucket + "/2023/10/f68d28b5-logo-black.png";
  }

  getSiteUrl() {
    return environment.siteUrl;
  }
  
}
