import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.css'
})
export class PricingComponent {

  getDiscoveryLink() {
    return environment.discoveryAgendaLink;
  }

  getFreeConsultationLink() {
    return environment.freeConsultationLink;
  }
}
