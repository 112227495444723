<div class="page">
    <div class="section">
           
                <div class="page-title">
                    <p>
                        <strong>Nice to meet you</strong>
                    </p> 
                </div>
            
        <div class="row">
            <div class="column">
                    <img decoding="async" fetchpriority="high" width="1024" height="768" src="{{getAboutMeUrl()}}" alt="" style="aspect-ratio:0.6666666666666666;object-fit:cover;width:242px;height:auto; margin: 1em;"  sizes="(max-width: 1024px) 100vw, 1024px">   
            </div>
            
                <div class="column content"  style="flex-basis:40%">
                    <div style="width: 30%;">
                        <hr class="separator" style="background-color:#000000;color:#000000"/>
                    </div>
                            <p>
                                I’m Jonathan Roquelaure, developer in heart, automation maker by conviction and DevOps expert with 15 years of experience leading technical teams, training, and advising about agility, CI/CD, cloud and DevOps.
                            </p>
                </div>
                <div class="column content"  style="flex-basis:40%">
                    
                        <p>
                            With a background in software development coding in languages like Java, C#, Python and Golang, I’ve always been drawn to the efficiency and precision that automation brings. This interest led me to specialize in automating delivery platforms, accelerating the value stream, and leveraging feedback loops for continuous improvement.
                        </p>
                </div>
        </div>
    </div>
    <div class="block-cta">
        
        <div class="block-cta-title">
            <p>
                Schedule a free consultation
            </p>
        </div>
        <div class="block-cta-container">
            <a href="{{getFreeConsultationLink()}}" class="block-button" style="max-width: 10em;">Book Now</a>
        </div>
        <div class="block-cta-details">
            <p>
                <strong> Let’s get to know each other. Schedule a free consultation to discuss your current projects and challenges around DevOps and Cloud.</strong>
            </p>
        </div>
    </div>
</div>
