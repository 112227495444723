import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-about-me',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './about-me.component.html',
  styleUrl: './about-me.component.css'
})
export class AboutMeComponent {
  getAboutMeUrl () {
    return "https://storage.googleapis.com/" + environment.mediaBucket + "/2023/10/1a550bfe-20190515_110736-768x1024.jpg"
    }

  getResumePDFUrl () {
    return "https://storage.googleapis.com/" + environment.mediaBucket + "/2023/10/9cd16580-resume-jonathan-roquelaure.pdf"
  }
}
