<div class="footer">
    <div class="block-group">
        <div class="block-group">
            <div class="site-logo">
                <a href="{{getSiteUrl()}}">
                    <img loading="lazy" width="40" height="40" src="{{ getLogo() }}" class="custom-logo" alt="Gajea Consulting" decoding="async" sizes="(max-width: 57px) 100vw, 57px" data-image-size="full">
         </a>
            </div>
            <h1 class="block-site-title" style="padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;margin-top:0;margin-right:0;margin-bottom:0;margin-left:0; font-style:normal;font-weight:100;letter-spacing:1px;">
                <a href="{{getSiteUrl()}}" target="_self" rel="home">
                    Gajea Consulting
                </a>
            </h1>
        </div>
        <div class="block-group">
            <h5></h5>
        </div>
        <div class="block-group">
            <ul class="">
                <li class="social-link">
                    <a class="fa fa-twitter" href="https://@RoquelaureJ" >
                       
                    </a>
                    <a class="fa fa-linkedin" href="https://www.linkedin.com/in/jonathan-roquelaure/" >
                       
                    </a>
                    <a class="fa fa-google" href="mailto:jon@gajea.com" >
                       
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>