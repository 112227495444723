import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-work-together',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './work-together.component.html',
  styleUrl: './work-together.component.css'
})
export class WorkTogetherComponent implements OnInit{
  constructor() {
  }

  getFreeConsultationLink() {
    return environment.discoveryAgendaLink;
  }

  getAboutMeUrl (){
    return "https://storage.googleapis.com/" + environment.mediaBucket + "/2023/10/e4b82bdb-20200216_124037-1024x768.jpg"
  }

  ngOnInit() {
  }
}
