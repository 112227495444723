<div class="page">
        <div class="row ">
            <div class="column" style="background-color: var(--color-background-alt);">
                <div class="block-cta">
                    <div class="block-cta-title">
                        Scoped mision
                    </div>
                    <div><strong>You know what you need.</strong></div>
                    
                    <div class="block-cta-details">
                        Let’s talk about your needs and evaluate together the complexity and schedule of the mission.
                    </div>
                    <div class="block-cta-container">
                        <a href="{{getDiscoveryLink()}}" class="block-button">Book 45 min discovery meeting</a>
                    </div>
                </div>
                
            </div>
            <div class="column" style="background-color:  var(--color-background-alt2);">
                <div class="block-cta">
                    <div class="block-cta-title">
                        Contractor
                    </div>
                    <div><strong>Get me on your team.</strong></div>
                    
                    <div class="block-cta-details">
                        Let’s discuss together the role you want me to fit in.

Ideal for a mid/long-term mission where I’ll be working just as another team member.
                    </div>
                    <div class="block-cta-container">
                        <a href="{{getDiscoveryLink()}}" class="block-button">Book 45 min discovery meeting</a>
                    </div>
                </div>
            </div>
            <div class="column"  style="background-color: var(--color-background-alt3);">
                <div class="block-cta">
                    <div class="block-cta-title">
                        Knowledge sharing
                    </div>
                    <div><strong>You’re not sure where to start.</strong></div>
                    <div class="block-cta-details">
                        Let's discuss your current DevOps and Cloud projects and any challenges you have.
                        
                        This is free of charge without any obligation.
                    </div>
                    <div class="block-cta-container">
                        <a href="{{getFreeConsultationLink()}}" class="block-button">Schedule free consultation</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="" >
            <p>
                <strong>Daily rate</strong>

            </p>
            <ul>
                <li>
                    Avg. 650 euros/day.
                </li>
            </ul>
            <p><strong>Rate influencers</strong></p>
            <ul>
                <li>
                   <strong>Complexity: </strong> Project intricacy and required expertise.
                </li>
                <li>
                    <strong>Environmental Impact: </strong> My project-related footprint, the project’s eco-impact, and your company’s green practices.
                 </li>
            </ul>
        </div>
    
    </div>