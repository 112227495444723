<div class="header">
  <div class="block-group">
    <div class="block-group" style="padding-top: 0;padding-right: 0;padding-bottom: 0;padding-left: 0;">
      <div class="site-logo">
          <a href="{{ getSiteUrl() }}">
              <img loading="lazy" width="40" height="40" src="{{ getLogo() }}" class="custom-logo" alt="Gajea Consulting" decoding="async" sizes="(max-width: 57px) 100vw, 57px" >
          </a>
      </div>
      <h1 class="block-site-title" style="font-style:normal;font-weight:100;letter-spacing:1px;">
          <a href="{{ getSiteUrl() }}" target="_self" rel="home">
              Gajea Consulting
          </a>
      </h1>
  </div>
  <div class="block-group">
      <h5></h5>
  </div>

  <nav mat-tab-nav-bar color="accent"  backgroundColor="primary" [tabPanel]="tabPanel">

      <a mat-tab-link
         *ngFor="let link of navLinks"
         [routerLink]="link.link"
         routerLinkActive #rla="routerLinkActive"
         [active]="rla.isActive">
        {{link.label}}
      </a>
      <mat-tab-nav-panel #tabPanel>
      </mat-tab-nav-panel>
  </nav>
  </div>
</div>